import React, { Dispatch, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Input, Modal } from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  AccessTokenOptions,
  saveClientGroup,
  updateUserGroup,
} from '../../apiHelpers'
import ApplicationSelectionForGroup from './ApplicationSelectionForGroup'
import UserSelectionForGroup from './UserSelectionForGroup'

export type Group = {
  clientHash: string
  groupName: string
  products: string[]
  groupId?: string
}

type ClientGroup = {
  clientGroups: Group
}

type GroupModalProps = {
  open: boolean
  setOpen: (x: boolean) => void
  modalTitle: string
  actionText: string
  modalCallback: (group: Group) => void
  forceUpdateGroups: () => void
}

const StyledInput = styled(Input)`
  margin-bottom: 8px;
  input {
    width: 664px;
  }
`

const StyledModal = styled(Modal)`
  width: 664px !important;
  height: auto !important;
`

const StyledUsersAndProducts = styled.div`
  display: flex;
  justify-content: space-between;
  width: 664px;
`

const GroupModal: React.FC<GroupModalProps> = ({
  open,
  setOpen,
  modalTitle,
  actionText,
  forceUpdateGroups,
}) => {
  const [groupName, setGroupName] = useState<string>('')
  const { clientHash } = useParams<{ clientHash: string }>()
  const [selectedApplications, setSelectedApplications] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [disabledButton, setDisabledButton] = useState<boolean>(true)
  const [modalVariant, setModalVariant] = useState<
    'primary' | 'success' | 'error'
  >('primary')
  const usersToRemove: string[] = []
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (selectedUsers.length > 0 && selectedApplications.length > 0) {
      setDisabledButton(false)
    }
  }, [selectedUsers, selectedApplications])

  const createGroupInput = (
    label: string,
    placeHolder: string,
    updateFunction: Dispatch<string>,
    value: string,
  ): JSX.Element => {
    return (
      <div>
        <StyledInput
          label={label}
          placeholder={placeHolder}
          required={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            updateFunction(event.target.value)
            setDisabledButton(true)
            setModalVariant('primary')
          }}
          value={value}
        />
      </div>
    )
  }

  const createGroup = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions),
    )
    await saveClientGroup(
      {
        clientHash: clientHash,
        groupName: groupName,
        products: selectedApplications.join(),
      },
      accessToken,
    )
      .then((response) => response.json())
      .then((responseJson: ClientGroup) => {
        const groupId = responseJson.clientGroups.groupId
        if (groupId) {
          return updateUserGroup(
            groupId,
            selectedUsers,
            usersToRemove,
            accessToken,
          )
        }
      })
      .then(() => {
        setModalVariant('success')
        forceUpdateGroups()
      })
      .catch((e) => {
        console.error('Error occurred', e)
        setModalVariant('error')
      })
  }

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <StyledModal
            title={modalTitle}
            variant={modalVariant}
            modalAction={{
              text: actionText,
              onClick: (): Promise<void> => {
                setDisabledButton(true)
                return createGroup()
              },
              disabled: disabledButton,
            }}
            handleClose={(): void => setOpen(false)}
          >
            <span>
              {createGroupInput(
                'Group Name',
                'Enter group name',
                setGroupName,
                groupName,
              )}
            </span>
            <StyledUsersAndProducts>
              <UserSelectionForGroup
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
              <ApplicationSelectionForGroup
                selectedApplications={selectedApplications}
                setSelectedApplications={setSelectedApplications}
              />
            </StyledUsersAndProducts>
          </StyledModal>,
          document.body,
        )}
    </>
  )
}

export default GroupModal
