import React from 'react'

import { ClientProductTournamentCalendar } from '../components/ClientPage/types'
import { APIS } from '../components/apiHelpers'
import { useApi } from '../hooks/useApi'

export type ClientProductTournamentCalendarProviderProps = {
  clientHash: string
  productId: string
}

export type ClientProductTournamentCalendarsResponse = {
  tournamentCalendars?: ClientProductTournamentCalendar[]
}

type ClientProductTournamentCalendarsProviderType = {
  tournamentCalendars?: ClientProductTournamentCalendar[]
  updateTournamentCalendars: React.DispatchWithoutAction
}

const DefaultClientProductTournamentCalendarContext = {
  tournamentCalendars: [],
  updateTournamentCalendars: (): null => null,
}

export const ClientProductTournamentCalendarContext =
  React.createContext<ClientProductTournamentCalendarsProviderType>(
    DefaultClientProductTournamentCalendarContext,
  )

export const ClientProductTournamentCalendarProvider: React.FC<
  ClientProductTournamentCalendarProviderProps
> = (props) => {
  const clientProductsUrl = `${APIS.CLIENTS}?service=clientProductsTournamentCalendars&client_hash=${props.clientHash}&product_id=${props.productId}`
  const { data: tournamentCalendars, refresh: updateTournamentCalendars } =
    useApi<ClientProductTournamentCalendarsResponse>(clientProductsUrl)

  const providerValue = {
    tournamentCalendars: tournamentCalendars?.tournamentCalendars,
    updateTournamentCalendars: updateTournamentCalendars,
  }

  return (
    <ClientProductTournamentCalendarContext.Provider value={providerValue}>
      {props.children}
    </ClientProductTournamentCalendarContext.Provider>
  )
}

export const useClientProductTournamentCalendarProvider =
  (): ClientProductTournamentCalendarsProviderType => {
    return React.useContext(ClientProductTournamentCalendarContext)
  }
