import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Search,
  SortableTH,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  palette,
} from '@stats/playbook-components'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  ClientResponse,
  useClientsProvider,
} from '../../providers/ClientsProvider'
import { APIS, AccessTokenOptions } from '../apiHelpers'
import Pagination from './Pagination/Pagination'
import { UserResponse } from './UsersTable'
import { useDebounce } from './useDebounce'

const StyledSearchWrapper = styled.div`
  margin: 0 0 24px 0;
`

const StyledTableWrapper = styled.div`
  height: calc(100vh - 404px);
  overflow: auto;
`

const StyledTable = styled(Table)`
  th {
    position: sticky;
    top: 0;
    background-color: ${palette['sp-black']};
  }

  & tbody tr:nth-child(odd):hover,
  & tbody tr:nth-child(even):hover {
    background-color: ${palette['sp-table-hover']};
  }
`

const ClickableRow = styled(TR)`
  cursor: pointer;
`

type OrganizationsTableProps = {
  pageSize?: number
}

type ClientIdAndTotalUsers = {
  clientId: string
  totalUsers: number
}

const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
  pageSize = 20,
}) => {
  const [searchTerm, changeSearchTerm] = useState<string>()
  const [sortCol, changeSortCol] = useState<string>('organizationName')
  const [sortDir, changeSortDir] = useState<boolean>(true)
  const history = useHistory()
  const { allClients } = useClientsProvider()
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0)
  const [clients, setClients] = useState<ClientResponse[]>()
  const [totalPages, setTotalPages] = useState<number>(0)
  const [clientsAndUsers, setClientsAndUsers] = useState<{
    clients: ClientIdAndTotalUsers[]
  }>()

  const debouncedSearchTerm = useDebounce(searchTerm, 750)
  const debouncedClients = useDebounce(clients, 750)
  const { getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    setCurrentPageNumber(0)
  }, [searchTerm])

  React.useEffect(() => {
    if (allClients) {
      const filteredClients = allClients.filter((client) => {
        return debouncedSearchTerm
          ? client.organizationName
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
          : true
      })

      setTotalPages(Math.ceil(filteredClients.length / pageSize))

      const clientsToDisplay = filteredClients
        .sort((a, b) => {
          const aSortValue = a[sortCol as keyof ClientResponse].toString()
          const bSortValue = b[sortCol as keyof ClientResponse].toString()
          return sortDir
            ? aSortValue.localeCompare(bSortValue)
            : bSortValue.localeCompare(aSortValue)
        })
        .slice(
          currentPageNumber * pageSize,
          currentPageNumber * pageSize + pageSize,
        )

      setClients(clientsToDisplay)
    }
  }, [
    currentPageNumber,
    allClients,
    pageSize,
    sortCol,
    sortDir,
    debouncedSearchTerm,
  ])

  useEffect(() => {
    const usersBaseURL = `${APIS.AUTH0}?service=users&client_hash=`
    let isMounted = true
    const fetchNumberOfUsers = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently(AccessTokenOptions)
      debouncedClients?.map((client) => {
        fetch(`${usersBaseURL}${client.clientId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => {
            if (response.ok) return response.json()
            else throw response
          })
          .then((responseJson: UserResponse) => {
            if (isMounted) {
              setClientsAndUsers((oldValue) => {
                const oldValueClients = oldValue?.clients || []
                return {
                  clients: [
                    ...oldValueClients,
                    {
                      clientId: client.clientId,
                      totalUsers: responseJson.users.total,
                    },
                  ],
                }
              })
            }
          })
          .catch((err) => {
            err.text().then((text: string) => console.error(text))
          })
      })
    }
    fetchNumberOfUsers().then()

    return (): void => {
      isMounted = false
    }
  }, [debouncedSearchTerm, debouncedClients, getAccessTokenSilently])

  const sortableHeader = (
    columnId: keyof ClientResponse,
    columnTitle: string,
  ): React.ReactNode => {
    return (
      <SortableTH
        changeSortCol={changeSortCol}
        changeSortDir={changeSortDir}
        columnId={columnId}
        columnTitle={columnTitle}
        isSorted={sortCol === columnId}
        sortDescending={sortDir}
      />
    )
  }

  return (
    <>
      <StyledSearchWrapper>
        <Search
          searchChangeHandler={changeSearchTerm}
          title='clientCatalogueSearch'
        />
      </StyledSearchWrapper>
      <StyledTableWrapper>
        <StyledTable>
          <THead>
            <TR>
              {sortableHeader('organizationName', 'Organization')}
              <TH>Products</TH>
              <TH># of Users</TH>
            </TR>
          </THead>
          <TBody>
            {clients &&
              clients.map((x) => {
                return (
                  <ClickableRow
                    key={`${x.clientId}-${x.organizationName}`}
                    onClick={(): void => history.push(`/clients/${x.clientId}`)}
                  >
                    <TD style={{ width: '50%' }}>{x.organizationName}</TD>
                    <TD style={{ width: '40%' }}>{x.products.join(', ')}</TD>
                    <TD style={{ width: '10%' }}>
                      {clientsAndUsers?.clients?.find(
                        (client) => client.clientId === x.clientId,
                      )?.totalUsers || 0}
                    </TD>
                  </ClickableRow>
                )
              })}
          </TBody>
        </StyledTable>
      </StyledTableWrapper>
      <Pagination
        currentPage={currentPageNumber}
        setPage={setCurrentPageNumber}
        totalPages={totalPages}
      />
    </>
  )
}

export default OrganizationsTable
