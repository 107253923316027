import React, { useState } from 'react'

import { Breadcrumbs } from '@stats/playbook-components'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { User } from '../AddUser/types'
import DeleteGroupModal from './DeleteGroupModal'

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .deleteGroup {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.33;
    cursor: pointer;
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  && {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  flex: 1 1;
`

type GroupPageHeaderMenuProps = {
  groupName: string
  clientHash: string
  groupId: string
  groupUsers: User[]
}

const GroupPageHeaderMenu: React.FC<GroupPageHeaderMenuProps> = ({
  clientHash,
  groupName,
  groupId,
  groupUsers,
}) => {
  const history = useHistory()
  const redirectToGroups = (): void =>
    history.push(`/clients/${clientHash}/groups`)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalState, setModalState] = useState<'none' | 'success' | 'error'>(
    'error',
  )

  return (
    <StyledHeader className='top-row'>
      <StyledBreadcrumbs
        items={[
          { name: 'Groups', handleClick: redirectToGroups },
          { name: groupName },
        ]}
      ></StyledBreadcrumbs>
      <span className='deleteGroup' onClick={(): void => setModalOpen(true)}>
        Delete Group
      </span>
      {modalOpen && (
        <DeleteGroupModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setModalState={setModalState}
          modalState={modalState}
          groupId={groupId}
          groupUsers={groupUsers}
          clientHash={clientHash}
        />
      )}
    </StyledHeader>
  )
}

export default GroupPageHeaderMenu
