import { Role } from './ProductPage/ProductPage.types'

// No longer using the productId in the role name (formerly pressBoxLive:package), need to map somehow
// Will eventually need to distinguish between Soccer and NFL and CFB, or other products
export const productIdToRoleNameFilterMap: Record<string, string> = {
  pressBoxLive: 'PBL:',
  baseDataPlatform: 'BDP%3A',
}

// Hardcoded ranking of roles since we don't want to have numbers in them
export const inheritingPackageTypes = ['basic', 'standard', 'premium']

export const nonInheritingPackageTypes = ['reports', 'tradingtool']

const packageTypeOrder = [
  ...nonInheritingPackageTypes,
  ...inheritingPackageTypes,
]
/** Split package name into prefix and type. e.g. ['PBL:SOCCER', 'premium'] */
export const getPackageNameSegments = (name: string): string[] => {
  const delimiterIndex = name.lastIndexOf(':')
  const prefix = name.slice(0, delimiterIndex)
  const type = name.slice(delimiterIndex + 1)
  return [prefix, type]
}

/** Order packages by sport and type. */
export const orderPackages = (packageList: Role[]): Role[] => {
  return packageList.sort((a, b) => {
    const [aPrefix, aType] = getPackageNameSegments(a.name)
    const [bPrefix, bType] = getPackageNameSegments(b.name)

    if (aPrefix === bPrefix) {
      // Find the type of the package, which will be the last portion of the package
      return packageTypeOrder.indexOf(aType) - packageTypeOrder.indexOf(bType)
    }
    return bPrefix > aPrefix ? 1 : -1
  })
}

export enum Relation {
  PARENT,
  CHILD,
}

export const getRelatedRoles = (
  role: Role,
  orderedRoles: Role[],
  relation: Relation,
): Role[] => {
  const [rolePrefix, roleType] = getPackageNameSegments(role.name)
  if (nonInheritingPackageTypes.includes(roleType)) {
    return relation === Relation.PARENT ? [] : [role]
  }

  const orderedRoleIndex = orderedRoles.findIndex(({ id }) => id === role.id)
  return orderedRoles.filter(({ name }, index) => {
    const [prefix, type] = getPackageNameSegments(name)
    const indexMatches =
      relation === Relation.PARENT
        ? index < orderedRoleIndex
        : index >= orderedRoleIndex
    return (
      rolePrefix === prefix &&
      indexMatches &&
      inheritingPackageTypes.includes(type)
    )
  })
}
