import React from 'react'

import { APIS } from '../components/apiHelpers'
import { useApi } from '../hooks/useApi'

export type ClientResponse = {
  clientId: string
  organizationName: string
  lastUpdated: string
  userCount: number
  products: string[]
}

type ClientsProviderType = {
  allClients?: ClientResponse[]
}

const DefaultClientsContext = {
  allClients: [],
}

export const ClientsContext = React.createContext<ClientsProviderType>(
  DefaultClientsContext,
)

// TODO: this needs a rewrite, as the list of clients keeps growing (noticed over 2000 in PBL-11609)
const clientsURL = `${APIS.CLIENTS}?service=clients&page_size=2500`

export const ClientsProvider: React.FC = (props) => {
  const { data: allClients } = useApi<{ clients: ClientResponse[] }>(clientsURL)

  const providerValue = {
    allClients: allClients && allClients.clients,
  }
  return (
    <ClientsContext.Provider value={providerValue}>
      {props.children}
    </ClientsContext.Provider>
  )
}

export const useClientsProvider = (): ClientsProviderType => {
  return React.useContext(ClientsContext)
}
