import React, { Dispatch, SetStateAction } from 'react'

import { Icon } from '@stats/playbook-components'
import styled from 'styled-components'

const StyledAddOnRole = styled.div`
  .add-on-role {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .add-on-icon {
    padding-right: 8px;
  }
`

type AddOnRoleProps = {
  addOn: string
  newAddOns: string[]
  setNewAddOns: Dispatch<SetStateAction<string[]>>
}

const AddOnRole: React.FC<AddOnRoleProps> = ({
  addOn,
  newAddOns,
  setNewAddOns,
}) => {
  const addOnActive = newAddOns.includes(addOn)

  return (
    <StyledAddOnRole>
      <div className='add-on-role'>
        <Icon
          variant={addOnActive ? 'checkbox-selected' : 'checkbox-unselected'}
          className='add-on-icon'
          onClick={(): void => {
            if (addOnActive) {
              const remainingAddOns = newAddOns.filter((x) => x !== addOn)
              setNewAddOns(remainingAddOns)
            } else {
              setNewAddOns([...newAddOns, addOn])
            }
          }}
        />
        {addOn.split(': ')[1]}
      </div>
    </StyledAddOnRole>
  )
}

export default AddOnRole
