import React from 'react'

import { APIS } from '../components/apiHelpers'
import { useApi } from '../hooks/useApi'

export type ClientAddOnsResponse = {
  clientId: string
  addOn: string
  isEnabled: boolean
  lastUpdated: string
}

export type ClientAddOnsProviderProps = {
  clientHash: string
}

type ClientAddOnsProviderType = {
  clientAddOns?: ClientAddOnsResponse[]
  retriggerAddOnsAPICall: () => void
}

export const ClientAddOnsContext =
  React.createContext<ClientAddOnsProviderType>({
    retriggerAddOnsAPICall: () => console.log('Retrigger not created'),
  })

export const ClientAddOnsProvider: React.FC<ClientAddOnsProviderProps> = (
  props,
) => {
  const clientAddOnsUrl = `${APIS.CLIENTS}?service=clientAddOnsV2&client_hash=${props.clientHash}`

  const { data: clientAddOns, refresh: handleRetriggerAPICall } = useApi<{
    addOns: ClientAddOnsResponse[]
  }>(clientAddOnsUrl)

  const providerValue = {
    clientAddOns:
      clientAddOns && clientAddOns.addOns?.filter((addOn) => addOn.isEnabled),
    retriggerAddOnsAPICall: (): void => {
      handleRetriggerAPICall()
    },
  }
  return (
    <ClientAddOnsContext.Provider value={providerValue}>
      {props.children}
    </ClientAddOnsContext.Provider>
  )
}

export const useClientAddOnsProvider = (): ClientAddOnsProviderType => {
  return React.useContext(ClientAddOnsContext)
}
