import { ProductVerticalResponse } from './providers/ProductVerticalsProvider'
import { Product } from './types'

const findProductById = (
  totalProducts: ProductVerticalResponse[],
  productIdorName: string,
): Product => {
  let targetProduct: Product = {} as Product
  for (let index = 0; index < totalProducts.length; index++) {
    const filteredProduct = totalProducts[index].products.filter(
      (x) => x.id === productIdorName,
    )

    if (filteredProduct.length > 0) {
      targetProduct = filteredProduct[0]
      targetProduct.verticalName = totalProducts[index].verticalName
      break
    }
  }
  return targetProduct
}

const findProductByPrimaryRoleId = (
  totalProducts: ProductVerticalResponse[],
  primaryRoleId: string,
): Product => {
  let targetProduct: Product = {} as Product
  for (let index = 0; index < totalProducts.length; index++) {
    const filteredProduct = totalProducts[index].products.filter(
      (x) => x.primaryRoleId === primaryRoleId,
    )

    if (filteredProduct.length > 0) {
      targetProduct = filteredProduct[0]
      targetProduct.verticalName = totalProducts[index].verticalName
      break
    }
  }
  return targetProduct
}

const flattenProducts = (
  totalProducts: ProductVerticalResponse[],
): Product[] => {
  let products: Product[] = []
  for (let index = 0; index < totalProducts.length; index++) {
    const tempProducts: Product[] = totalProducts[index].products
    tempProducts.forEach(
      (x) => (x.verticalName = totalProducts[index].verticalName),
    )
    products = products.concat(tempProducts)
  }

  return products
}

export { findProductById, findProductByPrimaryRoleId, flattenProducts }
