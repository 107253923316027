import React, { Dispatch, SetStateAction, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import { useHistory } from 'react-router-dom'

import { User } from '../AddUser/types'
import {
  AccessTokenOptions,
  removeClientGroup,
  updateUserGroup,
} from '../apiHelpers'

type DeleteGroupModalProps = {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  modalState: 'none' | 'success' | 'error'
  setModalState: Dispatch<SetStateAction<'none' | 'success' | 'error'>>
  groupId: string
  groupUsers: User[]
  clientHash: string
}

const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  setModalOpen,
  modalState,
  groupId,
  groupUsers,
  setModalState,
  clientHash,
}) => {
  const [modalTitle, setModalTitle] = useState<string>(
    'Permanently Delete Group?',
  )
  const removeGroupForUserIds = groupUsers.map((user) => user.user_id)
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const handleDeleteGroup = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions),
    )
    await updateUserGroup(groupId, [], removeGroupForUserIds, accessToken)
    await removeClientGroup(groupId, clientHash, accessToken).then((): void => {
      history.push(`/clients/${clientHash}/groups`)
    })
  }

  return (
    <Modal
      title={modalTitle}
      handleClose={(): void => setModalOpen(false)}
      variant={modalState === 'none' ? 'primary' : modalState}
      modalAction={{
        text: 'Delete Group',
        onClick: (): Promise<void> => {
          return handleDeleteGroup()
            .then((): void => {
              setModalState('success')
            })
            .catch((e) => {
              console.error('Error occurred', e)
              setModalState('error')
              setModalTitle('Error Deleting Group!')
            })
        },
      }}
    >
      <span>
        Are you sure you want to delete this group? All application permissions
        will be removed from users' profiles.
      </span>
    </Modal>
  )
}

export default DeleteGroupModal
