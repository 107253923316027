import React, { useState } from 'react'

import { Icon, Search, palette } from '@stats/playbook-components'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  flex: 1 1;

  .label-row {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
  }

  .count {
    font-weight: bold;
  }

  .menu {
    border: solid 1px ${palette['sp-light-gray-50']};
    border-radius: 2px;
    padding: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
    height: 345px;
    overflow: auto;
  }
`

const ReStyledMenuItem = styled.li`
  align-items: center;
  font-size: 12px;

  span {
    white-space: unset;
  }

  svg {
    min-width: 16px;
    min-height: 16px;
  }

  list-style: none;
  padding: 8px 32px 8px 0;
  position: relative;
  display: flex;

  &.readOnly {
    color: ${palette['sp-mid-gray']};
  }

  &:hover {
    background: ${palette['sp-hover-light']};
  }
  & .selected {
    position: absolute;
    top: 4px;
    right: 8px;
  }

  &:focus {
    outline: none;
    background: ${palette['sp-hover-light']};
  }
`

const StyledValueWrapper = styled.span`
  padding: 1px 0 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export type CompetitionMenuItem = {
  value: string
  displayValue: string
  competitionId: string
  tournamentId: string
  sport: string
}

export type MenuItem = {
  value: string
  displayValue?: string
}

type SelectorMenuProps = {
  label: string
  menuId: string
  menuItems: MenuItem[]
  selectedMenuItems: MenuItem[]
  onItemSelected: (value: string) => void
}

export const SelectorMenu: React.FC<SelectorMenuProps> = ({
  label,
  menuId,
  menuItems,
  selectedMenuItems,
  onItemSelected,
}) => {
  const [search, setSearch] = useState('')
  return (
    <StyledWrapper aria-labelledby={menuId}>
      <div className='label-row'>
        <label id={menuId}>{label}</label>
        <span className='count'>{` ${menuItems.length} total, ${selectedMenuItems.length} selected`}</span>
      </div>
      <div className='menu'>
        <Search searchChangeHandler={setSearch} />
        <ul>
          {menuItems
            ?.filter((item) => {
              const targetValue = item.displayValue || item.value
              return targetValue.toLowerCase().includes(search.toLowerCase())
            })
            .map((item) => (
              <ReStyledMenuItem
                key={item.value}
                onClick={(): void => onItemSelected(item.value)}
              >
                <Icon
                  style={{ cursor: 'pointer' }}
                  variant={
                    selectedMenuItems.includes(item)
                      ? 'checkbox-selected'
                      : 'checkbox-unselected'
                  }
                  size='small'
                />
                <StyledValueWrapper>
                  {item.displayValue || item.value}
                </StyledValueWrapper>
              </ReStyledMenuItem>
            ))}
        </ul>
      </div>
    </StyledWrapper>
  )
}
