import React from 'react'

import { Icon, palette, typography } from '@stats/playbook-components'
import classnames from 'classnames'
import styled from 'styled-components'

import { Relation, getRelatedRoles } from '../utils'
import { Permission, Role, RolePermission } from './ProductPage.types'

const StyledTitle = styled.div<{ valid: boolean }>`
  font-size: 12px;
  padding: 8px 0 12px 0;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${(props) => (props.valid ? palette['sp-black'] : palette['sp-gray'])};
`

const StyledWrapper = styled.div`
  ${typography};
  display: flex;
  flex-wrap: wrap;

  .item {
    cursor: pointer;
    flex: 1 0 34%;
    display: flex;
    align-content: center;
    margin-bottom: 16px;
    line-height: 1.2;
    span {
      margin-left: 8px;
    }
  }

  .invalid {
    color: ${palette['sp-gray']};
    cursor: default;
    pointer-events: none;
  }
`

type PermissionsSelectorProps = {
  productPermissions: Permission[]
  allowedPermissions: Permission[]
  role: Role
  setRolePermissions: (rolePermissions: any) => void
  rolePermissions: RolePermission[]
  orderedRoles: Role[]
}

const getIsChecked = (
  permission: string,
  roleId: string,
  rolePermissions?: RolePermission[],
): 'checkbox-selected' | 'checkbox-unselected' => {
  const permissionExists = rolePermissions?.some(
    (rolePermission) =>
      rolePermission.roleId === roleId &&
      rolePermission.permission === permission,
  )
  return permissionExists ? 'checkbox-selected' : 'checkbox-unselected'
}

export const PermissionsSelector: React.FC<PermissionsSelectorProps> = ({
  productPermissions,
  allowedPermissions,
  role,
  rolePermissions,
  setRolePermissions,
  orderedRoles,
}) => {
  const permissionExists = (permission: string): boolean => {
    return rolePermissions?.some((rolePermission) => {
      return (
        rolePermission.roleId === role.id &&
        rolePermission.permission === permission
      )
    })
  }

  const togglePermissions = (permission: string): void => {
    const inheritingRoleIds = getRelatedRoles(
      role,
      orderedRoles,
      Relation.CHILD,
    ).map(({ id }) => id)

    const filteredPermissionsByRoles = rolePermissions.filter(
      (rolePermission) => {
        // remove, if the permission's role is inheriting from another role (including role itself)
        return !(
          inheritingRoleIds.includes(rolePermission.roleId) &&
          rolePermission.permission === permission
        )
      },
    )

    if (permissionExists(permission)) {
      // if role already has permission, set to filtered role permissions
      setRolePermissions(filteredPermissionsByRoles)
    } else {
      // else add permission for all inheriting roles (including role itself)
      const rolesToAdd = inheritingRoleIds.map((roleId) => ({
        roleId,
        permission,
      }))
      setRolePermissions(filteredPermissionsByRoles.concat(...rolesToAdd))
    }
  }

  const nonWidgetPermissions = productPermissions.filter(
    (x) => !x.name.startsWith('Widget:'),
  )
  const widgetPermissions = productPermissions.filter((x) =>
    x.name.startsWith('Widget:'),
  )

  const widgetsAllowed =
    rolePermissions
      .filter((x) => x.roleId === role.id)
      .filter((x) => x.permission === 'Widgets').length > 0

  return (
    <>
      <StyledTitle valid={true}>Features</StyledTitle>
      <StyledWrapper>
        {nonWidgetPermissions.map((permission) => {
          const clickAllowed = allowedPermissions.includes(permission)
          return (
            <div
              key={permission.name}
              className={classnames('item', clickAllowed ? '' : 'invalid')}
              onClick={(): void => togglePermissions(permission.name)}
              title={permission.name}
            >
              <Icon
                variant={getIsChecked(
                  permission.name,
                  role.id,
                  rolePermissions,
                )}
                size='small'
                fill={clickAllowed ? 'sp-black' : 'sp-gray'}
              />
              <span>{permission.description}</span>
            </div>
          )
        })}
      </StyledWrapper>
      {productPermissions.map((x) => x.name).includes('Widgets') && (
        <>
          <StyledTitle valid={widgetsAllowed}>Widgets</StyledTitle>
          <StyledWrapper>
            {widgetPermissions.map((permission) => {
              const clickAllowed =
                allowedPermissions.includes(permission) && widgetsAllowed
              return (
                <div
                  key={permission.name}
                  className={classnames('item', clickAllowed ? '' : 'invalid')}
                  onClick={(): void => togglePermissions(permission.name)}
                  title={permission.name}
                >
                  <Icon
                    variant={getIsChecked(
                      permission.name,
                      role.id,
                      rolePermissions,
                    )}
                    size='small'
                    fill={clickAllowed ? 'sp-black' : 'sp-gray'}
                  />
                  <span>{permission.description}</span>
                </div>
              )
            })}
          </StyledWrapper>
        </>
      )}
    </>
  )
}

export default PermissionsSelector
