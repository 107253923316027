import React from 'react'

import { Icon } from '@stats/playbook-components'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

type RowWithCheckboxProps = {
  onClick: () => void
  displayName: string
  selectedItems: string[]
  rowItem: string
}

const RowWithCheckbox: React.FC<RowWithCheckboxProps> = ({
  onClick,
  displayName,
  rowItem,
  selectedItems,
}) => {
  return (
    <StyledWrapper onClick={onClick} key={rowItem}>
      <Icon
        variant={
          selectedItems?.includes(rowItem)
            ? 'checkbox-selected'
            : 'checkbox-unselected'
        }
      />
      <span>{displayName}</span>
    </StyledWrapper>
  )
}

export default RowWithCheckbox
