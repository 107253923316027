import React from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { ClientAddOnsProvider } from '../../providers/ClientAddOnsProvider'
import { ClientProductsProvider } from '../../providers/ClientProductsProvider'
import ClientProductPage from '../ClientProductPage/ClientProductPage'
import GroupPage from '../GroupPage/GroupPage'
import BaseClientPage from './BaseClientPage'
import ClientPageMain from './ClientPageMain'
import ClientPageGroups from './Groups/ClientPageGroups'

const ClientPage: React.FC = () => {
  const history = useHistory()
  const { clientHash, productId, groupId } = useParams<{
    clientHash: string
    productId: string
    groupId: string
  }>()
  const ClientPageUsersOrGroups = history.location.pathname.includes(
    'groups',
  ) ? (
    <ClientPageGroups />
  ) : (
    <ClientPageMain />
  )
  const ProductsOrUserGroups = productId ? (
    <ClientProductPage />
  ) : (
    ClientPageUsersOrGroups
  )
  const MainPageComponent = groupId ? <GroupPage /> : ProductsOrUserGroups
  return (
    <ClientProductsProvider clientHash={clientHash}>
      <ClientAddOnsProvider clientHash={clientHash}>
        <BaseClientPage
          clientHash={clientHash}
          MainPageComponent={MainPageComponent}
        />
      </ClientAddOnsProvider>
    </ClientProductsProvider>
  )
}

export default ClientPage
