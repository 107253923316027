import React from 'react'

import { palette } from '@stats/playbook-components'
import styled from 'styled-components'

import ClientCatalogue from './ClientCatalogue/ClientCatalogue'

const StyledPageWrapper = styled.div`
  padding: 32px;
  background-color: ${palette['sp-light-gray-50']};
  height: 100vh;
`

const UserManagementDashboard: React.FC = () => {
  return (
    <StyledPageWrapper>
      <ClientCatalogue pageSize={20} />
    </StyledPageWrapper>
  )
}

export default UserManagementDashboard
