import React, { useState } from 'react'

import { Icon, TD, TR } from '@stats/playbook-components'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useProductVerticalsProvider } from '../../../providers/ProductVerticalsProvider'
import { Product } from '../../../types'
import { User } from '../../AddUser/types'
import { Group } from './GroupModal'

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

type GroupDirectoryRowProps = {
  group: Group
  usersWithGroups?: User[]
}

const GroupDirectoryRow: React.FC<GroupDirectoryRowProps> = ({
  group,
  usersWithGroups,
}) => {
  const { clientHash }: { clientHash: string } = useParams()
  const history = useHistory()
  const [expanded, setExpanded] = useState<boolean>(false)
  const { totalProducts } = useProductVerticalsProvider()

  const groupId = group.groupId
  let groupProducts: Product[] = []
  totalProducts.forEach((vertical) => {
    groupProducts = groupProducts.concat(
      vertical.products.filter((product) =>
        group.products.includes(
          product.primaryRoleId ? product.primaryRoleId : '',
        ),
      ),
    )
  })
  const productNames = groupProducts.map((x) => x.name)
  const users = groupId
    ? usersWithGroups?.filter((x) =>
        x.user_metadata.groups.includes(groupId?.toString()),
      )
    : []
  const numberOfUsersInGroup = users?.length

  return (
    <>
      <TR key={group.groupId}>
        <TD>
          <StyledIcon
            variant={expanded ? 'chevron-up' : 'chevron-down'}
            onClick={(): void => setExpanded(!expanded)}
          />
        </TD>
        <TD>{group.groupId}</TD>
        <TD>{group.groupName}</TD>
        <TD>{numberOfUsersInGroup}</TD>
        <TD>{productNames.join(', ')}</TD>
        <TD>
          <StyledIcon
            variant='edit'
            onClick={(): void =>
              history.push(`/clients/${clientHash}/groups/${groupId}`)
            }
          />
        </TD>
      </TR>
      {expanded &&
        users?.map((user) => {
          return (
            <TR key={user.user_id}>
              <TD />
              <TD>{user.user_id}</TD>
              <TD>{user.name}</TD>
              <TD>{user.email}</TD>
              <TD />
              <TD />
            </TR>
          )
        })}
    </>
  )
}

export default GroupDirectoryRow
