import React, { SetStateAction, useState } from 'react'
import ReactDOM from 'react-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Dropdown, Input, Modal, palette } from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import styled from 'styled-components'

import {
  ApplicationsResponse,
  useApplicationsProvider,
} from '../../providers/ApplicationsProvider'
import { User } from '../AddUser/types'
import { StateVariants } from '../ClientPage/UserModal'
import { APIS, AccessTokenOptions } from '../apiHelpers'

type IntegrationModalProps = {
  setOpen: (x: boolean) => void
  open: boolean
  modalVariant: StateVariants
  modalTitle: string
  user: User
  setModalState: (
    value:
      | ((
          prevState: 'none' | 'success' | 'error',
        ) => 'none' | 'success' | 'error')
      | 'none'
      | 'success'
      | 'error',
  ) => void
  integrations: Application[]
  setIntegrations: (value: SetStateAction<Application[]>) => void
}

export type Application = {
  id: string
  applicationName: string
  userName?: string
  userId?: string
}

const StyledDropdown = styled(Dropdown)`
  margin-top: 12px;
  margin-bottom: 16px;
  z-index: 1;

  label::after {
    content: '*';
    color: ${palette['jordan']};
  }
`

const StyledInput = styled(Input)`
  padding: 12px 0;
`

const StyledModal = styled(Modal)`
  &.srs-tool {
    height: 320px;
  }
`

const IntegrationModal: React.FC<IntegrationModalProps> = ({
  modalTitle,
  setOpen,
  open,
  modalVariant,
  user,
  setModalState,
  integrations,
  setIntegrations,
}) => {
  const { applications } = useApplicationsProvider()
  const [legacyTool, setLegacyTool] = useState<
    ApplicationsResponse | undefined
  >(undefined)
  const [userName, setUsername] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const { getAccessTokenSilently } = useAuth0()

  const handleItemChange = (value: string): void => {
    const tool = applications?.filter((a) => a.name === value)[0]
    setLegacyTool(tool)
    setUsername('')
    setUserId('')
  }

  const modalSubmit = async (): Promise<void> => {
    setModalState('none')
    if (legacyTool !== undefined && (userName !== '' || userId !== '')) {
      let newIntegration: Application = {
        id: legacyTool.client_id,
        applicationName: legacyTool.name,
      }

      if (userName !== '') {
        newIntegration = {
          ...newIntegration,
          userName,
        }
      }

      if (userId !== '') {
        newIntegration = {
          ...newIntegration,
          userId,
        }
      }
      const body = JSON.stringify({
        user_id: user.user_id,
        update: {
          app_metadata: {
            ssoPortalAllowedApps: [...integrations, newIntegration],
          },
        },
      })
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions),
      )
      const res = await fetch(`${APIS.AUTH0}?service=update_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${accessToken}`,
        },
        body,
      })

      if (res.ok) {
        setIntegrations([...integrations, newIntegration])
        setUserId('')
        setUsername('')
        setLegacyTool(undefined)
        setModalState('success')
      } else {
        setUserId('')
        setUsername('')
        setLegacyTool(undefined)
        setModalState('error')
      }
    }
  }

  const displayUserName = (
    legacyTool: ApplicationsResponse | undefined,
  ): boolean => {
    const productsWithoutUserName: string[] = [
      'Z3QGm4R0DBLX4uWWa5gfyaKd550Ybexm', // Prod Opta Query
      'tvV4cQW4zF4SeJZw81kQTx3qXqsgNkIR', // Prod STATSPASS

      '4o92QNBebQ3EryIAW8DkWSPhlFs5Gthh', // Dev Opta Query
      'lfe3KCTYZYFHBmWsOfjCoSgou3RLX1wt', // Dev STATSPASS
    ]
    return legacyTool
      ? !productsWithoutUserName.includes(legacyTool.client_id)
      : true
  }

  const displayUserId = (
    legacyTool: ApplicationsResponse | undefined,
  ): boolean => {
    const productsWithUserId: string[] = [
      'Z3QGm4R0DBLX4uWWa5gfyaKd550Ybexm', // Prod Opta Query
      'PVe6lpTJVhneHDTKbDLRNIAQhyrBfTOb', // Prod SRS
      'tvV4cQW4zF4SeJZw81kQTx3qXqsgNkIR', // Prod STATSPASS

      '4o92QNBebQ3EryIAW8DkWSPhlFs5Gthh', // Dev Opta Query
      'THUigQ01be4pVktODF9WnnJpKrx4nLAd', // Dev SRS
      'lfe3KCTYZYFHBmWsOfjCoSgou3RLX1wt', // Dev STATSPASS
    ]

    return legacyTool
      ? productsWithUserId.includes(legacyTool.client_id)
      : false
  }

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <StyledModal
            title={modalTitle}
            variant={modalVariant}
            className={
              legacyTool &&
              [
                'THUigQ01be4pVktODF9WnnJpKrx4nLAd',
                'PVe6lpTJVhneHDTKbDLRNIAQhyrBfTOb',
              ].includes(legacyTool.client_id)
                ? 'srs-tool'
                : ''
            }
            handleClose={(): void => {
              setModalState('none')
              setOpen(false)
            }}
            modalAction={{
              text: 'Connect',
              onClick: modalSubmit,
            }}
          >
            <div>
              <StyledDropdown
                label='Legacy Tool '
                menuItems={
                  applications?.map((x) => {
                    return {
                      value: x.name,
                      label: x.client_id,
                    }
                  }) || []
                }
                onItemSelect={handleItemChange}
              />
              <div>
                {displayUserName(legacyTool) && (
                  <StyledInput
                    label='Username or Email Address'
                    required={true}
                    placeholder='Enter username or email address'
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void => setUsername(event.target.value)}
                    value={userName}
                  />
                )}
                {displayUserId(legacyTool) && (
                  <StyledInput
                    label='User ID'
                    required={true}
                    placeholder='Enter user ID'
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void => setUserId(event.target.value)}
                    value={userId}
                  />
                )}
              </div>
            </div>
          </StyledModal>,
          document.body,
        )}
    </>
  )
}

export default IntegrationModal
