import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useApi } from '../../hooks/useApi'
import { User } from '../AddUser/types'
import { ClientGroupResponse } from '../ClientPage/Groups/ClientPageGroups'
import { Group } from '../ClientPage/Groups/GroupModal'
import { UsersResponse } from '../ClientPage/types'
import { APIS } from '../apiHelpers'
import GroupApplicationsTile from './GroupApplicationsTile'
import GroupDetailsTile from './GroupDetailsTile'
import GroupPageHeaderMenu from './GroupPageHeaderMenu'
import GroupUsersTile from './GroupUsersTile'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type GroupPageProps = Record<string, string>

const GroupPage: React.FC<GroupPageProps> = () => {
  const { groupId, clientHash }: { groupId: string; clientHash: string } =
    useParams()
  const { data: clientGroups } = useApi<ClientGroupResponse>(
    encodeURI(`${APIS.CLIENTS}?service=clientGroups&client_hash=${clientHash}`),
  )
  const { data: allUsers, refresh: forceUpdate } = useApi<UsersResponse>(
    `${APIS.AUTH0}?service=users&client_hash=${clientHash}`,
  )
  const [groupUsers, setGroupUsers] = useState<User[]>([])
  const [selectedGroup, setSelectedGroup] = useState<Group>()

  useEffect(() => {
    setSelectedGroup(
      clientGroups?.clientGroups.find((x) => x.groupId === groupId),
    )
  }, [clientGroups, groupId])

  useEffect(() => {
    if (allUsers) {
      const usersWithGroups = allUsers.users.users.filter(
        (user) => user.user_metadata.groups !== undefined,
      )
      setGroupUsers(
        usersWithGroups.filter((x) => x.user_metadata.groups.includes(groupId)),
      )
    }
  }, [allUsers, groupId])

  const users = allUsers?.users.users
  return (
    <StyledWrapper>
      <GroupPageHeaderMenu
        groupName={selectedGroup?.groupName || groupId}
        clientHash={clientHash}
        groupId={groupId}
        groupUsers={groupUsers}
      />
      {selectedGroup && (
        <>
          <GroupDetailsTile groupId={groupId} selectedGroup={selectedGroup} />
          <GroupUsersTile
            groupUsers={groupUsers}
            allUsers={users || []}
            groupId={groupId}
            forceUpdate={forceUpdate}
          />
          <GroupApplicationsTile selectedGroup={selectedGroup} />
        </>
      )}
    </StyledWrapper>
  )
}

export default GroupPage
