import React, { Dispatch, useState } from 'react'
import ReactDOM from 'react-dom'

import { Icon, Input, Modal, palette } from '@stats/playbook-components'
import styled from 'styled-components'

import {
  InternalUserDetailUpdateFunctions,
  InternalUserDetailValues,
} from '../../types/UserProfileTypes'
import InternalUserDetails from '../InternalUserDetails/InternalUserDetails'
import ApplicationSelection from './ApplicationSelection'

const StyledModal = styled(Modal)<{ name: string; email: string }>`
  && {
    height: auto;
    min-height: 240px;
    max-height: 600px;
    overflow-y: scroll;
  }

  & .modal-footer & button &:disabled {
    color: ${(props): string =>
      props.name === '' && props.email === '' ? 'blue' : `${palette.jordan}`};
  }

  .internalUserCheckboxWrapper {
    display: flex;
    align-items: center;
  }

  .internalUserLabel {
    padding-top: 2px;
    font-size: 12px;
    margin-left: 8px;
  }
`

const StyledInput = styled(Input)`
  margin-bottom: 8px;

  input {
    width: calc(100% - 16px);
  }
`

export type StateVariants = 'success' | 'primary' | 'error'

export type UserState = {
  name: string
  email: string
  roles: string[]
}

type UserModalProps = {
  setOpen: (x: boolean) => void
  open: boolean
  modalVariant: StateVariants
  modalTitle: string
  actionText: string
  modalCallback: (
    user: UserState,
    internalUserDetails: InternalUserDetailValues,
  ) => void
  isInternalUser: boolean
  setIsInternalUser: (x: boolean) => void
  user?: UserState
}

const UserModal: React.FC<UserModalProps> = ({
  open,
  setOpen,
  modalVariant,
  modalTitle,
  actionText,
  modalCallback,
  isInternalUser,
  setIsInternalUser,
  user = {},
}) => {
  const [email, setEmail] = useState<string>(user?.email || '')
  const [name, setName] = useState<string>(user?.name || '')
  const [roles, setRoles] = useState<string[]>(user?.roles || [])
  const [country, setCountry] = useState<string>()
  const [region, setRegion] = useState<string>()
  const [city, setCity] = useState<string>()
  const [officeLocation, setOfficeLocation] = useState<string>()
  const [secondaryEmail, setSecondaryEmail] = useState<string>()
  const [countryCode, setCountryCode] = useState<string>()
  const [telephone, setTelephone] = useState<string>()

  const createUserInput = (
    label: string,
    placeHolder: string,
    updateFunction: Dispatch<string>,
    value: string,
  ): JSX.Element => {
    return (
      <div>
        <StyledInput
          label={label}
          placeholder={placeHolder}
          required={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            updateFunction(event.target.value)
          }
          value={value}
          data-testid={label}
        />
      </div>
    )
  }

  const internalUserDetails: InternalUserDetailValues = {
    country,
    region,
    city,
    officeLocation,
    secondaryEmail,
    countryCode,
    telephone,
  }

  const getIsButtonDisabled = (): boolean => {
    const missingName = name === ''
    const missingEmail = email === ''
    let missingInternalDetails = false
    if (isInternalUser) {
      missingInternalDetails =
        !country ||
        !region ||
        !city ||
        !officeLocation ||
        !countryCode ||
        !telephone
    }
    return missingName || missingEmail || missingInternalDetails
  }

  const handleClick = (): void => {
    modalCallback(
      {
        name: name,
        email: email,
        roles: roles,
      },
      {
        ...internalUserDetails,
        isInternalUser,
      },
    )
  }

  const internalUserDetailsUpdateFunctions: InternalUserDetailUpdateFunctions =
    {
      setCity,
      setCountry,
      setRegion,
      setOfficeLocation,
      setCountryCode,
      setTelephone,
      setSecondaryEmail,
    }

  // Empty because we are handling dirty differently in this case (conditional on internal user or not)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptySetDirtyFunction = (): void => {}

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <StyledModal
            name={name}
            email={email}
            title={modalTitle}
            variant={modalVariant}
            handleClose={(): void => setOpen(false)}
            modalAction={{
              text: actionText,
              onClick: handleClick,
              disabled: getIsButtonDisabled(),
            }}
          >
            <div>
              {createUserInput('Full Name', 'Enter name', setName, name)}
              {createUserInput(
                'Email Address',
                'Enter email address',
                setEmail,
                email,
              )}
            </div>
            <div
              className='internalUserCheckboxWrapper'
              onClick={(): void => setIsInternalUser(!isInternalUser)}
              data-testid='internal-employee-checkbox'
            >
              <Icon
                variant={
                  isInternalUser ? 'checkbox-selected' : 'checkbox-unselected'
                }
                size='small'
              />
              <span className='internalUserLabel'>
                This is a Stats Perform employee
              </span>
            </div>
            {isInternalUser && (
              <InternalUserDetails
                internalUserDetails={internalUserDetails}
                internalUserDetailUpdateFunctions={
                  internalUserDetailsUpdateFunctions
                }
                setDirty={emptySetDirtyFunction}
                inputFieldWidth={368}
              />
            )}
            <ApplicationSelection roles={roles} setRoles={setRoles} />
          </StyledModal>,
          document.body,
        )}
    </>
  )
}

export default UserModal
