import React, { useEffect, useState } from 'react'

import { Search, palette } from '@stats/playbook-components'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useApi } from '../../../hooks/useApi'
import { User } from '../../ClientCatalogue/UsersTable'
import { APIS } from '../../apiHelpers'
import { UsersResponse } from '../types'
import RowWithCheckbox from './RowWithCheckbox'

const StyledBoxWrapper = styled.div`
  border: ${palette['sp-light-gray-50']} 1px solid;
  padding: 16px 6px 0 16px;
  width: 300px;
  margin: 0 8px 8px 0;
`

const StyledSearch = styled(Search)`
  padding: 0 6px 6px 0;
`

const StyledTitleAndCount = styled.div`
  width: 324px;
  margin-top: 16px;
  font-size: 10px;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
`

const StyledScrollWrapper = styled.div`
  height: 324px;
  overflow-y: scroll;
`

const StyledComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type UserSelectionForGroupProps = {
  selectedUsers: string[]
  setSelectedUsers: (x: string[]) => void
}

const UserSelectionForGroup: React.FC<UserSelectionForGroupProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  const [searchTerm, changeSearchTerm] = useState<string>()
  const usersBaseURL = `${APIS.AUTH0}?service=users&client_hash=`
  const { clientHash } = useParams<{ clientHash: string }>()
  const { data: allUsers } = useApi<UsersResponse>(
    `${usersBaseURL}${clientHash}`,
  )
  const [allUsersList, setAllUsersList] = useState<User[]>(
    (allUsers && allUsers.users.users) || [],
  )
  const [usersToDisplay, setUsersToDisplay] = useState(allUsersList)

  useEffect(() => {
    if (allUsers) setAllUsersList(allUsers.users.users)
  }, [allUsers])

  useEffect(() => {
    if (searchTerm) {
      setUsersToDisplay(
        allUsersList.filter((user) =>
          user.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
        ),
      )
    } else setUsersToDisplay(allUsersList)
  }, [searchTerm, allUsersList])

  const toggleUser = (userId: string): void => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((user) => user !== userId))
    } else {
      setSelectedUsers(selectedUsers.concat(userId))
    }
  }

  return (
    <StyledComponentWrapper>
      <StyledTitleAndCount>
        <span>Users</span>
        <span>{`${selectedUsers?.length} selected`}</span>
      </StyledTitleAndCount>
      <StyledBoxWrapper>
        <StyledSearch
          searchChangeHandler={changeSearchTerm}
          title='userSearchBar'
        />
        <StyledScrollWrapper>
          {usersToDisplay.map((user) => {
            return (
              <RowWithCheckbox
                key={user.user_id}
                onClick={(): void => toggleUser(user.user_id)}
                displayName={user.name}
                rowItem={user.user_id}
                selectedItems={selectedUsers}
              />
            )
          })}
        </StyledScrollWrapper>
      </StyledBoxWrapper>
    </StyledComponentWrapper>
  )
}

export default UserSelectionForGroup
