import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'

import { InternalUserDetailValues } from '../../types/UserProfileTypes'
import { AccessTokenOptions, createNewUser, editUserInfo } from '../apiHelpers'
import UserModal, { UserState } from './UserModal'

export type ModalActionState = 'none' | 'success' | 'error'

const stateTitles = {
  none: 'Add User',
  success: 'user created',
  error: 'user creation failed',
}

type AddUserModalProps = Record<string, string>

const AddUserModal: React.FC<AddUserModalProps> = (props) => {
  const { clientHash } = useParams<{ clientHash: string }>()
  const [open, setOpen] = React.useState(false)
  const [userAdded, setUserAdded] = useState<ModalActionState>('none')
  const organizationName = props.organizationName as string
  const { getAccessTokenSilently } = useAuth0()

  const [isInternalUser, setIsInternalUser] = useState<boolean>(false)

  const callAddUserApi = async (
    user: UserState,
    internalUserDetails: InternalUserDetailValues,
  ): Promise<void> => {
    const { email, name, roles } = user
    if (email && name) {
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions),
      )
      const response = await createNewUser({
        email,
        name,
        clientHash,
        organizationName,
        accessToken,
        internalUserDetails,
      })
      if (roles.length && response.ok) {
        const responseJson = await response.json()
        const userId = responseJson.users.user_id
        const rolesResponse = await editUserInfo({
          email,
          name,
          userId,
          rolesToAdd: roles,
          rolesToRemove: [],
          appMetaData: {},
          accessToken,
        })
        setUserAdded(rolesResponse.ok ? 'success' : 'error')
        return
      }
      setUserAdded(response.ok ? 'success' : 'error')
    }
  }

  return (
    <>
      <UserModal
        setOpen={setOpen}
        open={open}
        modalVariant={userAdded === 'none' ? 'primary' : userAdded}
        modalTitle={stateTitles[userAdded]}
        actionText='Create User'
        modalCallback={callAddUserApi}
        isInternalUser={isInternalUser}
        setIsInternalUser={setIsInternalUser}
      />
      <Button variant='primary' onClick={(): void => setOpen(!open)}>
        Add User
      </Button>
    </>
  )
}

export default AddUserModal
