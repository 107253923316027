import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { trackPromise } from 'react-promise-tracker'

import { AccessTokenOptions, Options } from '../components/apiHelpers'

export const useApi = <T>(
  url: string,
  options: Options = AccessTokenOptions,
): {
  loading: boolean
  error: any
  data: T | undefined
  refresh: React.DispatchWithoutAction
} => {
  const [state, setState] = useState({
    error: undefined,
    loading: true,
    data: undefined,
  })
  const [refreshIndex, setRefreshIndex] = useState(0)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    let isMounted = true
    ;(async (): Promise<void> => {
      try {
        const {
          ignoreCache,
          redirect_uri,
          audience,
          scope,
          timeoutInSeconds,
          ...fetchOptions
        } = options
        const accessToken = await getAccessTokenSilently({
          ignoreCache,
          redirect_uri,
          audience,
          scope,
          timeoutInSeconds,
        })
        const res = await trackPromise(
          fetch(url, {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              // Add the Authorization header to the existing headers
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        )
        if (isMounted) {
          setState({
            ...state,
            data: await res.json(),
            error: undefined,
            loading: false,
          })
        }
      } catch (error: any) {
        console.log(error)
        setState({
          ...state,
          error,
          loading: false,
        })
      }
    })()

    return (): void => {
      isMounted = false
    }
  }, [refreshIndex, url, options])

  return {
    ...state,
    refresh: (): void => setRefreshIndex(refreshIndex + 1),
  }
}
