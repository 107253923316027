import React from 'react'

import { useParams } from 'react-router-dom'

import { useApi } from '../../hooks/useApi'
import { ClientProductsProvider } from '../../providers/ClientProductsProvider'
import BaseClientPage from '../ClientPage/BaseClientPage'
import { UserResponse } from '../ClientPage/types'
import { APIS } from '../apiHelpers'
import UserProfileMain from './UserProfileMain'

const UserProfile: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const { data } = useApi<UserResponse>(
    encodeURI(`${APIS.AUTH0}?service=users&userId=${userId}`),
  )

  if (!data) {
    return null
  }

  const handleNoClientHash =
    data.users.user.user_metadata &&
    data.users.user.user_metadata['organizationId']
      ? data.users.user.user_metadata['organizationId']
      : ''

  return (
    <ClientProductsProvider clientHash={handleNoClientHash}>
      <BaseClientPage
        clientHash={handleNoClientHash}
        MainPageComponent={<UserProfileMain />}
      />
    </ClientProductsProvider>
  )
}

export default UserProfile
