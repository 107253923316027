import React, { useEffect, useState } from 'react'

import {
  Switch,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  Tile,
} from '@stats/playbook-components'
import styled from 'styled-components'

import { useClientProductsProvider } from '../../providers/ClientProductsProvider'
import { useProductVerticalsProvider } from '../../providers/ProductVerticalsProvider'
import { Product } from '../../types'
import { UserResponse } from '../ClientPage/types'
import { Application } from './IntegrationModal'

const StyledSwitch = styled(Switch)`
  &.legacy {
    pointer-events: none;
    opacity: 0.3;
  }
`

type ApplicationsTileProps = {
  data: UserResponse
  selectedRoles: string[]
  setSelectedRoles: (x: string[]) => void
  changeDirty: (x: boolean) => void
  integrations: Application[]
}

const ApplicationsTile: React.FC<ApplicationsTileProps> = ({
  data,
  selectedRoles,
  setSelectedRoles,
  changeDirty,
  integrations,
}) => {
  const { totalProducts } = useProductVerticalsProvider()
  const { clientProducts } = useClientProductsProvider()
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([])

  useEffect(() => {
    if (clientProducts) {
      const enabledClientProducts = clientProducts
        .filter((x) => x.isEnabled)
        .map((x) => x.productId)

      let enabledProducts: Product[] = []

      totalProducts.forEach((vertical) => {
        enabledProducts = enabledProducts.concat(
          vertical.products.filter((x) =>
            enabledClientProducts?.includes(x.primaryRoleId),
          ),
        )
      })

      setDisplayedProducts(enabledProducts)
    }
  }, [clientProducts])

  const toggleSelectedRole = (role: string): void => {
    if (selectedRoles?.includes(role)) {
      setSelectedRoles(selectedRoles.filter((x) => x !== role))
    } else {
      setSelectedRoles(selectedRoles.concat([role]))
    }
    changeDirty(true)
  }

  return (
    <Tile style={{ width: '100%' }} title='Applications'>
      <Table>
        <THead>
          <TR>
            <TH />
            <TH>Application</TH>
          </TR>
        </THead>
        <TBody>
          {displayedProducts.map((role) => (
            <TR key={role.id}>
              <TD>
                <Switch
                  role='checkbox'
                  defaultChecked={(
                    data.users.roles.map((r) => r.id) || []
                  ).includes(role.primaryRoleId)}
                  onClick={(): void => toggleSelectedRole(role.primaryRoleId)}
                />
              </TD>
              <TD style={{ width: '90%' }}>{role.name}</TD>
            </TR>
          ))}
          {integrations.map((integration) => {
            return (
              <TR key={integration.id}>
                <TD>
                  <StyledSwitch
                    className='legacy'
                    role='checkbox'
                    defaultChecked={true}
                  />
                </TD>
                <TD style={{ width: '90%' }}>{integration.applicationName}</TD>
              </TR>
            )
          })}
        </TBody>
      </Table>
    </Tile>
  )
}

export default ApplicationsTile
