import React, { useEffect, useState } from 'react'

import { palette } from '@stats/playbook-components'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useApi } from '../../hooks/useApi'
import {
  InternalUserDetailUpdateFunctions,
  InternalUserDetailValues,
} from '../../types/UserProfileTypes'
import { UserResponse } from '../ClientPage/types'
import { APIS } from '../apiHelpers'
import ApplicationsTile from './ApplicationsTile'
import DetailsTile from './DetailsTile'
import { Application } from './IntegrationModal'
import IntegrationTile from './IntegrationTile'
import UserProfileHeaderMenu from './UserProfileHeaderMenu'

const StyledWrapper = styled.div`
  .top-row {
    display: flex;
    padding: 8px 0 12px;

    .breadcrumbs {
      flex: 1 1;
    }

    .actions > button {
      margin-left: 16px;
    }
  }

  a {
    color: ${palette['sp-mid-gray']};
    text-decoration: none;
  }

  .read-only-label {
    font-size: 10px;
    color: ${palette['sp-dark-gray-25']};
    padding: 8px 0;

    & + div {
      padding-bottom: 16px;
    }
  }
`

const StyledCardRow = styled.div`
  display: flex;

  .details-tile {
    flex: 1;
    margin-right: 8px;
    background-color: ${palette['sp-white']};
    height: 524px;
  }

  .integration-tile {
    flex: 1;
    margin-left: 8px;
    background-color: ${palette['sp-white']};
  }
`

const UserProfileMain: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const [name, setName] = useState<string>()
  const [language, setLanguage] = useState<string>('en-GB')
  const [country, setCountry] = useState<string>()
  const [region, setRegion] = useState<string>()
  const [city, setCity] = useState<string>()
  const [officeLocation, setOfficeLocation] = useState<string>()
  const [secondaryEmail, setSecondaryEmail] = useState<string>()
  const [countryCode, setCountryCode] = useState<string>()
  const [telephone, setTelephone] = useState<string>()
  const [isInternalUser, setIsInternalUser] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const { data, refresh: forceUpdate } = useApi<UserResponse>(
    encodeURI(`${APIS.AUTH0}?service=users&userId=${userId}`),
  )
  const ssoPortalAllowedApps =
    data?.users.user.app_metadata?.ssoPortalAllowedApps || []
  const [integrations, setIntegrations] =
    useState<Application[]>(ssoPortalAllowedApps)

  useEffect(() => {
    if (data) {
      setName(data.users.user.name)
      setSelectedRoles(data.users.roles.map((x) => x.id))
      setIntegrations(data.users.user.app_metadata?.ssoPortalAllowedApps || [])
      setLanguage(data.users.user.user_metadata?.language || 'en-GB')

      setCountry(data.users.user.user_metadata?.country)
      setRegion(data.users.user.user_metadata?.region)
      setCity(data.users.user.user_metadata?.city)
      setOfficeLocation(data.users.user.user_metadata?.officeLocation)
      setSecondaryEmail(data.users.user.user_metadata?.secondaryEmail)
      setCountryCode(data.users.user.user_metadata?.countryCode)
      setTelephone(data.users.user.user_metadata?.telephone)

      if (data.users.user.user_metadata?.isInternalUser !== undefined) {
        setIsInternalUser(data.users.user.user_metadata.isInternalUser)
      }
    }
  }, [data])

  if (!data) {
    console.warn(
      "test 'should show the Applications Tile and 2 roles' fails only in jenkins. temporary work around",
    )
    return null
  }

  const internalUserDetails: InternalUserDetailValues = {
    country,
    region,
    city,
    officeLocation,
    secondaryEmail,
    countryCode,
    telephone,
    isInternalUser,
  }

  const internalUserDetailUpdateFunctions: InternalUserDetailUpdateFunctions = {
    setCountry,
    setRegion,
    setCity,
    setOfficeLocation,
    setSecondaryEmail,
    setCountryCode,
    setTelephone,
  }

  return (
    <StyledWrapper>
      <UserProfileHeaderMenu
        data={data}
        dirty={dirty}
        name={name}
        language={language}
        forceUpdate={forceUpdate}
        selectedRoles={selectedRoles}
        setDirty={setDirty}
        integrations={integrations}
        internalUserDetails={internalUserDetails}
      />
      <StyledCardRow>
        <DetailsTile
          data={data}
          name={name}
          setName={setName}
          setDirty={setDirty}
          setLanguage={setLanguage}
          language={language}
          isInternalUser={isInternalUser}
          setIsInternalUser={setIsInternalUser}
          internalUserDetails={internalUserDetails}
          internalUserDetailUpdateFunctions={internalUserDetailUpdateFunctions}
        />
        <IntegrationTile
          user={data.users.user}
          integrations={integrations}
          setIntegrations={setIntegrations}
          changeDirty={setDirty}
        />
      </StyledCardRow>
      <ApplicationsTile
        data={data}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        changeDirty={setDirty}
        integrations={integrations}
      />
    </StyledWrapper>
  )
}

export default UserProfileMain
