import React from 'react'

import { palette } from '@stats/playbook-components'
import styled from 'styled-components'

import ProductPageMain from './ProductPageMain'
import ProductPageSide from './ProductPageSide'

const StyledWrapper = styled.div`
  display: flex;
  background-color: ${palette['sp-light-gray-25']};
  min-height: calc(100vh - 72px);
  height: calc(100% - 72px);

  .side {
    flex: 0 0 272px;
    background-color: ${palette['sp-black']};
    padding: 24px;
  }

  .main {
    margin: 16px;
    width: calc(100vw - 272px);
  }
`

const ProductPage: React.FC = () => {
  return (
    <StyledWrapper>
      <div className='side'>
        <ProductPageSide />
      </div>
      <div className='main'>
        <ProductPageMain />
      </div>
    </StyledWrapper>
  )
}

export default ProductPage
