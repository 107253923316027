import React from 'react'

import { MultiSelect } from '@stats/playbook-components'
import maxBy from 'lodash/maxBy'
import uniqBy from 'lodash/uniqBy'
import styled from 'styled-components'

import { useClientProductTournamentCalendarProvider } from '../../providers/ClientProductTournamentCalendarProvider'
import EditLeaguesModal from '../EditLeagues/EditLeaguesModal'
import SecondaryHeaderAndButtons from './SecondaryHeaderAndButtons'
import SportsLeagueTable from './SportsLeagueTable'
import { League } from './types'

const SecondRow = styled.div`
  display: flex;
`

const StyledDropdown = styled(MultiSelect)`
  flex: 1 1;
  & .dropdown-field {
    display: flex;
    justify-content: flex-start;
    width: 208px;
    border: none;
    background-color: transparent;
    padding-left: 0;
  }
`

const listOfSports = [
  { name: 'Football', value: 'soccer' },
  { name: 'Basketball', value: 'basketball' },
  { name: 'Cricket', value: 'cricket' },
  { name: 'Tennis', value: 'tennis' },
]
type LeaguesAndCompetitionsProps = {
  clientHash: string
  productId: string
}

const LeaguesAndCompetitions: React.FC<LeaguesAndCompetitionsProps> = ({
  clientHash,
  productId,
}) => {
  const { tournamentCalendars } = useClientProductTournamentCalendarProvider()
  const [lastUpdated, setLastUpdated] = React.useState<string>('N/A')
  const [leagues, setLeagues] = React.useState<League[]>()
  const [selectedSports, setSelectedSports] =
    React.useState<{ value: string; name: string }[]>(listOfSports)

  const handleSelectedSports = React.useCallback(
    (sportNames: string[]) => {
      const filteredSports = listOfSports.filter((x) =>
        sportNames.includes(x.value),
      )
      if (filteredSports.length !== selectedSports.length) {
        setSelectedSports(filteredSports)
      }
    },
    [selectedSports.length],
  )

  React.useEffect(() => {
    setLeagues(
      uniqBy(tournamentCalendars, 'leagueId').map((tc) => ({
        id: tc.leagueId,
        name: tc.leagueName,
        sport: tc.sport,
      })),
    )
    setLastUpdated(
      maxBy(tournamentCalendars, 'lastUpdated')?.lastUpdated || 'N/A',
    )
  }, [tournamentCalendars])

  return (
    <>
      <SecondaryHeaderAndButtons
        title='Leagues & Competitions'
        lastUpdated={lastUpdated}
        enabledSaveButton={true}
      />
      <SecondRow>
        <StyledDropdown
          menuItems={listOfSports}
          includeSelectAll={true}
          initialSelectedItems={listOfSports}
          itemDisplayType='Sport'
          handleSelectedItemsChange={handleSelectedSports}
        />
        <EditLeaguesModal clientHash={clientHash} productId={productId} />
      </SecondRow>
      {selectedSports &&
        selectedSports.map((sport: { value: string; name: string }) => {
          return (
            <SportsLeagueTable
              key={sport.name}
              sportName={sport.name}
              leagues={leagues?.filter(
                (league) => league.sport === sport.value,
              )}
            />
          )
        })}
    </>
  )
}

export default LeaguesAndCompetitions
