import React, { SetStateAction } from 'react'

import { Icon, palette } from '@stats/playbook-components'
import styled from 'styled-components'

import { Application } from './IntegrationModal'

const ApplicationRowWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const ApplicationName = styled.div`
  font-size: 10px;
  padding-bottom: 8px;
  color: ${palette['sp-dark-gray-25']};
`

const UserNameAndX = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

type ApplicationRowProps = {
  application: Application
  integrations: Application[]
  setIntegrations: (x: SetStateAction<Application[]>) => void
  changeDirty: (x: SetStateAction<boolean>) => void
}

const ApplicationRow: React.FC<ApplicationRowProps> = ({
  application,
  integrations,
  setIntegrations,
  changeDirty,
}) => {
  const { applicationName, userName, userId } = application
  const handleOnClick = (): void => {
    const newIntegrations = integrations.filter((x) => x.id !== application.id)
    setIntegrations(newIntegrations)
    changeDirty(true)
  }

  return (
    <ApplicationRowWrapper>
      <ApplicationName>{applicationName}</ApplicationName>
      <UserNameAndX>
        {userName || userId}
        <Icon variant='x' onClick={handleOnClick} />
      </UserNameAndX>
    </ApplicationRowWrapper>
  )
}

export default ApplicationRow
