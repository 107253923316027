import React from 'react'

import { TBody, TD, TH, THead, TR, Table } from '@stats/playbook-components'
import styled from 'styled-components'

import { League } from './types'

const StyledTable = styled(Table)`
  .sport-header {
    text-transform: uppercase;
    font-size: 12px;
  }
`

type SportsLeagueTableProps = {
  sportName: string
  leagues?: League[]
}

const SportsLeagueTable: React.FC<SportsLeagueTableProps> = ({
  sportName,
  leagues,
}) => {
  return (
    <StyledTable>
      <THead>
        <TR>
          <TH className='sport-header'>{sportName}</TH>
        </TR>
      </THead>
      <TBody>
        {leagues &&
          leagues.map((league) => {
            return (
              <TR key={league.id}>
                <TD>{league.name}</TD>
              </TR>
            )
          })}
      </TBody>
    </StyledTable>
  )
}

export default SportsLeagueTable
