import React from 'react'

import { Tile, palette } from '@stats/playbook-components'
import styled from 'styled-components'

import { Group } from '../ClientPage/Groups/GroupModal'

type GroupDetailsProps = {
  groupId: string
  selectedGroup: Group
}

const StyledLabelText = styled.div`
  font-size: 10px;
  color: ${palette['sp-light-gray-75']};
`

const StyledText = styled.div`
  padding: 8px 0;
`

const GroupDetailsTile: React.FC<GroupDetailsProps> = ({
  groupId,
  selectedGroup,
}) => {
  return (
    <Tile title='Details'>
      <StyledLabelText>Group ID</StyledLabelText>
      <StyledText>{groupId}</StyledText>
      <StyledLabelText>Group Name</StyledLabelText>
      <StyledText>{selectedGroup.groupName}</StyledText>
    </Tile>
  )
}

export default GroupDetailsTile
