import React from 'react'

import { APIS } from '../components/apiHelpers'
import { useApi } from '../hooks/useApi'

export type ApplicationsResponse = {
  client_id: string
  name: string
}

export type ApplicationsProviderProps = {
  clientHash: string
}

type ApplicationsProviderType = {
  applications?: ApplicationsResponse[]
}

export const ApplicationsContext =
  React.createContext<ApplicationsProviderType>({})

export const ApplicationsProvider: React.FC = ({ children }) => {
  const applicationsUrl = `${APIS.AUTH0}?service=applications`

  const { data } = useApi<{ applications: ApplicationsResponse[] }>(
    applicationsUrl,
  )
  const providerValue = {
    applications: data && data.applications,
  }
  return (
    <ApplicationsContext.Provider value={providerValue}>
      {children}
    </ApplicationsContext.Provider>
  )
}

export const useApplicationsProvider = (): ApplicationsProviderType => {
  return React.useContext(ApplicationsContext)
}
