import React from 'react'

import { Tabs, Tile } from '@stats/playbook-components'
import styled from 'styled-components'

import OrganizationsTable from './OrganizationsTable'
import UsersTable from './UsersTable'

const StyledTile = styled(Tile)`
  width: 100%;
  position: relative;
`

const StyledTabs = styled(Tabs)`
  position: absolute;
  top: 20px;
  right: 24px;
`

type ClientCatalogueProps = {
  pageSize?: number
}

const ClientCatalogue: React.FC<ClientCatalogueProps> = ({ pageSize = 20 }) => {
  const [activeTab, setActiveTab] = React.useState<string>('organizations')
  return (
    <StyledTile title='Client Catalogue'>
      <StyledTabs
        tabList={[
          { title: 'Organizations', id: 'organizations' },
          { title: 'Users', id: 'users' },
        ]}
        onChangeCallback={(id: string): void => {
          setActiveTab(id)
        }}
      />
      {activeTab === 'organizations' && (
        <OrganizationsTable pageSize={pageSize} />
      )}
      {activeTab === 'users' && <UsersTable pageSize={pageSize} />}
    </StyledTile>
  )
}

export default ClientCatalogue
