import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Breadcrumbs, Button } from '@stats/playbook-components'
import difference from 'lodash/difference'
import { trackPromise } from 'react-promise-tracker'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { InternalUserDetailValues } from '../../types/UserProfileTypes'
import DeleteUserModal from '../ClientPage/DeleteUserModal'
import { UserResponse } from '../ClientPage/types'
import { AccessTokenOptions, editUserInfo } from '../apiHelpers'
import { Application } from './IntegrationModal'

const StyledBreadcrumbs = styled(Breadcrumbs)`
  && {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  flex: 1 1;
`

const StyledHeader = styled.div`
  .delete-account {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 16px;
    cursor: pointer;
  }
`

type UserProfileHeaderMenuProps = {
  data: UserResponse
  dirty: boolean
  selectedRoles: string[]
  setDirty: (x: boolean) => void
  forceUpdate: () => void
  name?: string
  integrations: Application[]
  language: string
  internalUserDetails: InternalUserDetailValues
}

const UserProfileHeaderMenu: React.FC<UserProfileHeaderMenuProps> = ({
  data,
  selectedRoles,
  setDirty,
  forceUpdate,
  dirty,
  name,
  integrations,
  language,
  internalUserDetails,
}) => {
  const history = useHistory()

  const [modalState, setModalState] = useState<'none' | 'success' | 'error'>(
    'error',
  )
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { getAccessTokenSilently } = useAuth0()

  const callEditUserApi = async (): Promise<void> => {
    if (data && name) {
      const currentRoles = data?.users.roles.map((x) => x.id) || []

      const existingAppMetadata = data?.users.user.app_metadata || {}
      const newAppMetaData = {
        ...existingAppMetadata,
        ssoPortalAllowedApps: integrations,
      }
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions),
      )
      await editUserInfo({
        email: data.users.user.email,
        name,
        userId: data.users.user.user_id,
        rolesToAdd: difference(selectedRoles, currentRoles),
        rolesToRemove: difference(currentRoles, selectedRoles),
        appMetaData: newAppMetaData,
        userConnection:
          data?.users.user.identities[0].connection || 'noConnectionFound',
        userMetadata: {
          language,
          organizationId: takeBackToUsersTable,
          ...internalUserDetails,
        },
        accessToken,
      })
      setDirty(false)
      forceUpdate()
    }
  }
  const takeBackToUsersTable =
    data.users.user.user_metadata &&
    data.users.user.user_metadata['organizationId']
      ? data.users.user.user_metadata['organizationId']
      : ''
  const redirectToUsers = (): void =>
    history.push(`/clients/${takeBackToUsersTable}`)

  return (
    <StyledHeader className='top-row'>
      <StyledBreadcrumbs
        items={[
          { name: 'Users', handleClick: redirectToUsers },
          { name: data.users.user.name, active: true },
        ]}
      />
      <span className='actions'>
        <Button variant='primary' onClick={callEditUserApi} disabled={!dirty}>
          Save
        </Button>
        <Button
          variant='secondary-light'
          onClick={(): void =>
            history.push(
              `/clients/${data.users.user.user_metadata['organizationId']}`,
            )
          }
        >
          Cancel
        </Button>
        <span
          className='delete-account'
          onClick={(): void => {
            setModalOpen(true)
            console.log('clicked delete account')
          }}
        >
          Delete Account
        </span>
        <DeleteUserModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalState={modalState}
          setModalState={setModalState}
          userId={data.users.user.user_id}
          redirectToUsers={redirectToUsers}
        />
      </span>
    </StyledHeader>
  )
}

export default UserProfileHeaderMenu
