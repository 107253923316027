import React, { Dispatch, SetStateAction } from 'react'

import { palette } from '@stats/playbook-components'
import styled from 'styled-components'

import AddOnRole from './AddOnRole'

const StyledAddOns = styled.div`
  .included-addons {
    width: 100%;
    height: 360px;
    border: solid 1px ${palette['sp-light-gray-50']};
    background: ${palette['sp-light-gray-25']};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
  }

  .features-title {
    font-size: 10px;
    line-height: 1.6;
  }
`

type ProductAddOnsProps = {
  addOnNames: string[]
  newAddOns: string[]
  setNewAddOns: Dispatch<SetStateAction<string[]>>
}

const ProductAddOns: React.FC<ProductAddOnsProps> = ({
  addOnNames,
  newAddOns,
  setNewAddOns,
}) => {
  return addOnNames.length > 0 ? (
    <StyledAddOns>
      <div className='features-title'>Add-ons</div>
      <div className='included-addons'>
        {addOnNames.map((addOn) => {
          return (
            <AddOnRole
              key={addOn}
              addOn={addOn}
              newAddOns={newAddOns}
              setNewAddOns={setNewAddOns}
            />
          )
        })}
      </div>
    </StyledAddOns>
  ) : null
}

export default ProductAddOns
