import React, { Dispatch } from 'react'

import { Dropdown } from '@stats/playbook-components'

import { Role } from '../../ProductPage/ProductPage.types'

type PackageRolesDropdown = {
  packageRoles: Role[]
  setSelectedPackage: Dispatch<string>
  selectedPackage?: string
  clientProductPackageId?: string
}

const PackageRolesDropdown: React.FC<PackageRolesDropdown> = ({
  packageRoles,
  setSelectedPackage,
  clientProductPackageId,
}) => {
  const currentPackage = packageRoles.find(
    (x) => x.id === clientProductPackageId,
  )?.name

  return packageRoles.length > 0 ? (
    <div className='package-selection'>
      <Dropdown
        label='Package'
        menuItems={
          packageRoles
            ? packageRoles.map((x) => {
                return {
                  value: x.id,
                  name: x.name,
                }
              })
            : []
        }
        value={currentPackage}
        onItemSelect={setSelectedPackage}
        className='packages-dropdown'
      />
    </div>
  ) : null
}

export default PackageRolesDropdown
