import React from 'react'

import { palette } from '@stats/playbook-components'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useClientProductsProvider } from '../../providers/ClientProductsProvider'
import { Product } from '../../types'

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  color: ${palette['sp-light-gray-75']};
  text-transform: uppercase;
  margin: 16px 0;
`

const StyledWrapper = styled.div`
  margin: 48px 0 32px 0;

  .groups {
    padding-top: 16px;
  }
`

const StyledUsersGroupsLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  color: ${palette['sp-light-gray-75']};
  text-transform: uppercase;
  margin: 16px 0;
`

const ProductOrRoleIndicator = styled.div`
  position: relative;
  font-size: 16px;
  color: ${palette['sp-white']};
  padding: 8px 16px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  left: -16px;
  width: 100%;
  cursor: pointer;

  &.current {
    background-color: ${palette['sp-dark-gray-50']};
    font-weight: 500;
  }

  .product-or-role-name {
    flex: 1 1;
  }

  .circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${palette['sp-light-gray-75']};
    &.active {
      background-color: ${palette['serena']};
    }
  }
`

type ClientRolesListProps = {
  products: Product[]
  clientHash: string
}

const NavigationOptions: React.FC<ClientRolesListProps> = ({
  products,
  clientHash,
}) => {
  const history = useHistory()
  const { clientProducts } = useClientProductsProvider()
  const clientProductIds = clientProducts
    ? clientProducts.map((clientProduct) => clientProduct.productId)
    : []
  const { productId } = useParams<{ productId: string }>()
  const groupsTab = history.location.pathname.includes('groups')
  const usersTab = history.location.pathname === `/clients/${clientHash}`

  const handleOnClick = (additionalPath?: string): void => {
    const url = additionalPath
      ? `/clients/${clientHash}${additionalPath}`
      : `/clients/${clientHash}`
    history.push(url)
  }
  return (
    <div>
      <StyledWrapper>
        <StyledUsersGroupsLabel>Users</StyledUsersGroupsLabel>
        <ProductOrRoleIndicator
          key='users-link'
          className={classNames({ current: usersTab })}
          onClick={(): void => handleOnClick()}
        >
          <div className='product-or-role-name '>Users</div>
        </ProductOrRoleIndicator>
        <ProductOrRoleIndicator
          key='groups-link'
          className={classNames({ current: groupsTab })}
          onClick={(): void => handleOnClick('/groups')}
        >
          <div className='product-or-role-name '>Groups</div>
        </ProductOrRoleIndicator>
      </StyledWrapper>
      <StyledLabel>Products</StyledLabel>
      {products.map((product) => {
        const productClassName = classNames({
          current: product.primaryRoleId === productId,
        })
        const circleClassName = classNames('circle', {
          active: clientProductIds.includes(product.primaryRoleId),
        })
        return (
          <ProductOrRoleIndicator
            key={product.id}
            className={productClassName}
            onClick={(): void =>
              handleOnClick(`/product/${product.primaryRoleId}`)
            }
          >
            <div className='product-or-role-name'>{product.name}</div>
            <div className={circleClassName} />
          </ProductOrRoleIndicator>
        )
      })}
    </div>
  )
}

export default NavigationOptions
