import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon, Modal } from '@stats/playbook-components'
import difference from 'lodash/difference'
import { trackPromise } from 'react-promise-tracker'
import styled from 'styled-components'

import { User } from '../AddUser/types'
import { SelectorMenu } from '../EditLeagues/SelectorMenu'
import { AccessTokenOptions, updateUserGroup } from '../apiHelpers'

const StyledModalLauncher = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 24px;
`

const StyledWrapper = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .selectors {
    display: flex;
  }

  .arrows {
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`

type EditGroupUsersModalProps = {
  options: User[]
  selected: User[]
  groupId: string
  forceUpdate: React.DispatchWithoutAction
}

type UserWithValue = {
  value: string
} & User

const EditGroupUsersModal: React.FC<EditGroupUsersModalProps> = ({
  options,
  selected,
  groupId,
  forceUpdate,
}) => {
  const [open, setOpen] = React.useState(false)
  const [saveEnabled, setSaveEnabled] = React.useState(false)
  const [available, setAvailable] = React.useState<UserWithValue[]>([])
  const [selectedAvailable, setSelectedAvailable] = React.useState<
    UserWithValue[]
  >([])
  const [permissioned, setPermissioned] = React.useState<UserWithValue[]>([])
  const [selectedPermissioned, setSelectedPermissioned] = React.useState<
    UserWithValue[]
  >([])
  const [modalVariant, setModalVariant] = useState<
    'primary' | 'success' | 'error'
  >('primary')
  const usersToAdd = permissioned.map((user) => user.user_id)
  const selectedUserIds = selected.map((u) => u.user_id)
  const usersToRemove = difference(selectedUserIds, usersToAdd)
  const { getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    const optionsWithValue = options.map((user) => {
      return { ...user, value: user.email }
    })
    const selectedWithValue = selected.map((user) => {
      return { ...user, value: user.email }
    })
    const optionsMinusSelected = optionsWithValue.filter((option) =>
      selectedWithValue.indexOf(option),
    )
    setAvailable(optionsMinusSelected)
    setPermissioned(selectedWithValue)
  }, [options, selected])

  const availableOnItemSelected = (value: string): void => {
    if (selectedAvailable.filter((s) => s.value === value).length === 1) {
      setSelectedAvailable(selectedAvailable.filter((s) => s.value !== value))
    } else {
      const newItem = available.find((a) => a.value === value)
      newItem && setSelectedAvailable([...selectedAvailable, newItem])
    }
  }

  const permissionedOnItemSelected = (value: string): void => {
    if (selectedPermissioned.filter((s) => s.value === value).length === 1) {
      setSelectedPermissioned(
        selectedPermissioned.filter((s) => s.value !== value),
      )
    } else {
      const newItem = permissioned.find((a) => a.value === value)
      newItem && setSelectedPermissioned([...selectedPermissioned, newItem])
    }
  }

  const availableOnClick = (): void => {
    setSaveEnabled(true)
    setPermissioned([...permissioned, ...selectedAvailable])
    setSelectedAvailable([])
    setAvailable(difference(available, selectedAvailable))
  }

  const permissionedOnClick = (): void => {
    setSaveEnabled(true)
    setAvailable([...available, ...selectedPermissioned])
    setSelectedPermissioned([])
    setPermissioned(difference(permissioned, selectedPermissioned))
  }

  const updateGroupUsers = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions),
    )
    await updateUserGroup(groupId, usersToAdd, usersToRemove, accessToken)
      .then(() => {
        setModalVariant('success')
        setSaveEnabled(false)
        forceUpdate()
      })
      .catch((e) => {
        console.error('Error occurred', e)
        setModalVariant('error')
      })
  }

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <Modal
            title='Edit Users'
            style={{ height: '604px', width: '800px' }}
            handleClose={(): void => setOpen(false)}
            variant={modalVariant}
            modalAction={{
              text: 'Save',
              onClick: (): Promise<void> => updateGroupUsers(),
              disabled: !saveEnabled,
            }}
          >
            <StyledWrapper>
              <div className='row'></div>
              <div className='row selectors'>
                <SelectorMenu
                  menuId='available'
                  label='Users'
                  menuItems={available}
                  selectedMenuItems={selectedAvailable}
                  onItemSelected={availableOnItemSelected}
                />
                <div className='arrows'>
                  <Icon
                    variant='chevron-right'
                    fill='sp-mid-gray'
                    onClick={availableOnClick}
                  />
                  <Icon
                    variant='chevron-left'
                    fill='sp-mid-gray'
                    onClick={permissionedOnClick}
                  />
                </div>
                <SelectorMenu
                  menuId='permissioned'
                  label='Users Added'
                  menuItems={permissioned}
                  selectedMenuItems={selectedPermissioned}
                  onItemSelected={permissionedOnItemSelected}
                />
              </div>
            </StyledWrapper>
          </Modal>,
          document.body,
        )}
      <StyledModalLauncher
        onClick={(): void => setOpen(!open)}
        variant='primary-black'
      >
        Add User
      </StyledModalLauncher>
    </>
  )
}

export default EditGroupUsersModal
