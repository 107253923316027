import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Tile } from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  ProductVerticalResponse,
  useProductVerticalsProvider,
} from '../../providers/ProductVerticalsProvider'
import { findProductById } from '../../utils'
import { AccessTokenOptions, saveRolePermissions } from '../apiHelpers'
import { ProductGroupInfo, RolePermission } from './ProductPage.types'
import ProductRoles from './ProductRoles'

const StyledTile = styled(Tile)`
  width: 100%;
  .action-row {
    display: flex;
    .title {
      flex: 1 1;
      font-weight: bold;
      text-transform: uppercase;
    }
    .buttons {
      button {
        margin-left: 16px;
      }
    }
  }
`

export const getProductGroupInfo = (
  totalProducts: ProductVerticalResponse[],
  productId: string,
): ProductGroupInfo | null => {
  let productGroupInfo: ProductGroupInfo | null = null

  const foundProduct = findProductById(totalProducts, productId)
  if (foundProduct !== undefined) {
    productGroupInfo = {
      groupName: foundProduct.verticalName || '',
      product: foundProduct,
    }
  }

  return productGroupInfo
}

const ProductPageMain: React.FC = () => {
  const { productId } = useParams<{ productId: string }>()
  const [rolePermissions, setRolePermissions] = useState<RolePermission[]>([])
  const { totalProducts } = useProductVerticalsProvider()
  const { getAccessTokenSilently } = useAuth0()

  if (!productId) {
    return null
  }

  const productGroupInfo = getProductGroupInfo(totalProducts, productId)
  if (!productGroupInfo) return null

  const productName = `${productGroupInfo.product.name}`

  const updateRolePermissions = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions),
    )
    saveRolePermissions(
      rolePermissions,
      productId,
      productGroupInfo.product.url,
      accessToken,
    ).then((response) => console.log(response))
  }

  return (
    <StyledTile title={productName}>
      <div className='action-row'>
        <div className='title'>Packages</div>
        <div className='buttons'>
          <Button
            variant='primary'
            onClick={(): Promise<void> => updateRolePermissions()}
          >
            Save
          </Button>
          <Button variant='secondary-light'>Cancel</Button>
        </div>
      </div>
      <ProductRoles
        key={productGroupInfo.product.id}
        product={productGroupInfo}
        rolePermissions={rolePermissions}
        setRolePermissions={setRolePermissions}
      />
    </StyledTile>
  )
}

export default ProductPageMain
