import React, { useEffect } from 'react'

import { Accordion } from '@stats/playbook-components'

import { useApi } from '../../hooks/useApi'
import { APIS } from '../apiHelpers'
import {
  Relation,
  getRelatedRoles,
  orderPackages,
  productIdToRoleNameFilterMap,
} from '../utils'
import { PermissionsSelector } from './PermissionsSelector'
import {
  Permission,
  ProductApiResponse,
  ProductGroupInfo,
  Role,
  RolePermission,
  RolesResponse,
} from './ProductPage.types'

type ProductRolesProps = {
  product: ProductGroupInfo
  setRolePermissions: (rolePermissions: any) => void
  rolePermissions: RolePermission[]
}

const ProductRoles: React.FC<ProductRolesProps> = ({
  product,
  rolePermissions,
  setRolePermissions,
}) => {
  const productId = product.product.id

  const nameFilter = productIdToRoleNameFilterMap[productId] || productId

  const { data: packages } = useApi<RolesResponse>(
    `${APIS.AUTH0}?service=roles&name_filter=${nameFilter}&include_permissions=true`,
  )
  const { data: productApi } = useApi<ProductApiResponse>(
    `${APIS.AUTH0}?service=resource_servers&rs_id=${product.product.url}`,
  )

  useEffect(() => {
    if (packages) {
      const permissionsPerRole = packages?.roles.roles
        .map((role) =>
          role.permissions.map((permission) => ({
            roleId: role.id,
            permission: permission.name,
          })),
        )
        .flat()
      setRolePermissions(permissionsPerRole)
    }
  }, [packages, setRolePermissions])

  if (!packages || !productApi?.resourceServer) {
    return null
  }

  const nonAddOnPackages = packages.roles.roles.filter(
    (x) => !x.name.includes('AddOn'),
  )

  const orderedRoles: Role[] = orderPackages(nonAddOnPackages)

  const productPermissions = productApi.resourceServer.scopes
    .filter((x) => !x.value.includes('AddOn'))
    .map((x) => ({
      name: x.value,
      description: x.description,
    }))

  const getAllowedPermissions = (role: Role): Permission[] => {
    const parentRoleIds = getRelatedRoles(
      role,
      orderedRoles,
      Relation.PARENT,
    ).map(({ id }) => id)
    const parentRolePermissions = rolePermissions.filter(({ roleId }) =>
      parentRoleIds.includes(roleId),
    )
    // filter out if role is inheriting the permission from another role (from same sport)
    return productPermissions.filter(
      (permission) =>
        !parentRolePermissions.some(
          ({ permission: parentPermission }) =>
            permission.name === parentPermission,
        ),
    )
  }

  return (
    <Accordion
      panels={orderedRoles.map((role) => ({
        title: role.name,
        content: (
          <PermissionsSelector
            productPermissions={productPermissions}
            allowedPermissions={getAllowedPermissions(role)}
            role={role}
            rolePermissions={rolePermissions}
            setRolePermissions={setRolePermissions}
            orderedRoles={orderedRoles}
          />
        ),
      }))}
    />
  )
}

export default ProductRoles
