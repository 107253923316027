import React from 'react'

import { APIS } from '../components/apiHelpers'
import { useApi } from '../hooks/useApi'

export type ClientProductsResponse = {
  clientId: string
  productId: string
  isEnabled: boolean
  lastUpdated: string
}

export type ClientProductsProviderProps = {
  clientHash: string
}

type ClientProductsProviderType = {
  clientProducts?: ClientProductsResponse[]
  retriggerProductsAPICall: () => void
}

export const ClientProductsContext =
  React.createContext<ClientProductsProviderType>({
    retriggerProductsAPICall: () => console.log('Retrigger not created'),
  })

export const ClientProductsProvider: React.FC<ClientProductsProviderProps> = (
  props,
) => {
  const clientProductsUrl = `${APIS.CLIENTS}?service=clientProducts&client_hash=${props.clientHash}`

  const { data: clientProducts, refresh: handleRetriggerAPICall } = useApi<{
    products: ClientProductsResponse[]
  }>(clientProductsUrl)

  const providerValue = {
    clientProducts:
      clientProducts &&
      clientProducts.products.filter((product) => product.isEnabled),
    retriggerProductsAPICall: (): void => {
      handleRetriggerAPICall()
    },
  }
  return (
    <ClientProductsContext.Provider value={providerValue}>
      {props.children}
    </ClientProductsContext.Provider>
  )
}

export const useClientProductsProvider = (): ClientProductsProviderType => {
  return React.useContext(ClientProductsContext)
}
