import React, { SetStateAction } from 'react'

import { Dropdown, Icon, Input, Tile } from '@stats/playbook-components'
import styled from 'styled-components'

import {
  InternalUserDetailUpdateFunctions,
  InternalUserDetailValues,
} from '../../types/UserProfileTypes'
import { UserResponse } from '../ClientPage/types'
import InternalUserDetails from '../InternalUserDetails/InternalUserDetails'

type DetailsTileProps = {
  data: UserResponse
  setDirty: (x: boolean) => void

  setName: (value: SetStateAction<string | undefined>) => void
  name?: string

  setLanguage: (value: string) => void
  language: string

  setIsInternalUser: (value: boolean) => void
  isInternalUser: boolean

  internalUserDetails: InternalUserDetailValues
  internalUserDetailUpdateFunctions: InternalUserDetailUpdateFunctions
}

const StyledDropdown = styled(Dropdown)`
  margin-top: 12px;
  margin-bottom: 16px;
  z-index: 1;
  width: 264px;
`

const ScrollableWrapper = styled.div`
  height: 416px;
  overflow-y: scroll;

  .internalUserCheckboxWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .internalUserLabel {
    font-size: 12px;
    margin-left: 8px;
    padding-top: 4px;
  }
`

const DetailsTile: React.FC<DetailsTileProps> = ({
  data,
  name,
  setName,
  setDirty,
  language,
  setLanguage,
  isInternalUser,
  setIsInternalUser,
  internalUserDetails,
  internalUserDetailUpdateFunctions,
}) => {
  const handleChangeName = (event: React.FormEvent<HTMLInputElement>): void => {
    setName(event.currentTarget.value)
    setDirty(true)
  }

  const handleChangeLanguage = (value: string): void => {
    setLanguage(languages.filter((l) => l.value === value)[0].label)
    setDirty(true)
  }

  const handleChangeIsInternalUser = (): void => {
    setIsInternalUser(!isInternalUser)
    setDirty(true)
  }

  const languages = [
    {
      value: 'English (British)',
      label: 'en-GB',
    },
    {
      value: 'English (US)',
      label: 'en-US',
    },
    {
      value: 'Italian',
      label: 'it-IT',
    },
    {
      value: 'German',
      label: 'de-DE',
    },
    {
      value: 'Spanish',
      label: 'es-ES',
    },
    {
      value: 'French',
      label: 'fr-FR',
    },
    {
      value: 'LatAm Spanish',
      label: 'es-419',
    },
    {
      value: 'Turkish',
      label: 'tr-TR',
    },
    {
      value: 'Arabic',
      label: 'ar-SA',
    },
    {
      value: 'Chinese',
      label: 'zh-CN',
    },
    {
      value: 'Japanese',
      label: 'ja-JP',
    },
    {
      value: 'Korean',
      label: 'ko-KR',
    },
    {
      value: 'Portuguese',
      label: 'pt-PT',
    },
    {
      value: 'Portuguese (Brazil)',
      label: 'pt-BR',
    },
    {
      value: 'Dutch',
      label: 'nl-NL',
    },
    {
      value: 'Polish',
      label: 'pl-PL',
    },
    {
      value: 'Thai',
      label: 'th-TH',
    },
    {
      value: 'Malay',
      label: 'ms-MY',
    },
  ]

  return (
    <Tile className='details-tile' title='Details'>
      <ScrollableWrapper>
        <div className='read-only-label'>User ID</div>
        <div>{data.users.user.user_id}</div>
        <div className='read-only-label'>SSO Email Address</div>
        <div>{data.users.user.email}</div>
        <div className='read-only-label'>Password</div>
        <div>•••••••••••</div>
        <div style={{ width: '264px' }}>
          <Input label='Full Name' value={name} onChange={handleChangeName} />
        </div>
        <div key={language}>
          <StyledDropdown
            label='SSO Default Language'
            menuItems={languages}
            value={
              languages.find((l) => l.label === language)?.value || 'English'
            }
            onItemSelect={handleChangeLanguage}
          />
        </div>
        <div
          className='internalUserCheckboxWrapper'
          onClick={handleChangeIsInternalUser}
          data-testid='internal-user-checkbox'
        >
          <Icon
            variant={
              isInternalUser ? 'checkbox-selected' : 'checkbox-unselected'
            }
            size='small'
          />
          <span className='internalUserLabel'>
            This is a Stats Perform employee
          </span>
        </div>
        {isInternalUser && (
          <InternalUserDetails
            internalUserDetails={internalUserDetails}
            internalUserDetailUpdateFunctions={
              internalUserDetailUpdateFunctions
            }
            setDirty={setDirty}
          />
        )}
      </ScrollableWrapper>
    </Tile>
  )
}
export default DetailsTile
