import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Icon,
  Search,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  palette,
} from '@stats/playbook-components'
import { trackPromise } from 'react-promise-tracker'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { APIS, AccessTokenOptions } from '../apiHelpers'
import Pagination from './Pagination/Pagination'
import VerifyUserModal from './VerifyUserModal'

const StyledSearchWrapper = styled.div`
  margin: 0 0 24px 0;
`

const StyledTableWrapper = styled.div`
  height: calc(100vh - 404px);
  overflow: auto;
`

const StyledTable = styled(Table)`
  th {
    position: sticky;
    top: 0;
    background-color: ${palette['sp-black']};
  }

  & tbody tr:nth-child(odd):hover,
  & tbody tr:nth-child(even):hover {
    background-color: ${palette['sp-table-hover']};
  }
`

const StyledRow = styled(TR)<{ status: string }>`
  .status {
    color: ${(props) =>
      props.status === 'Unvetted'
        ? `${palette['jordan']}`
        : `${palette['sp-black']}`};
  }

  .edit {
    cursor: pointer;
    margin-top: 8px;
    width: 2%;
  }

  .verify {
    font-weight: 500;
    width: 2%;
    line-height: 1.33;
    cursor: pointer;
  }
`

type UsersTableProps = {
  pageSize?: number
}

export type User = {
  user_id: string
  name: string
  email: string
  app_metadata: any
  user_metadata: {
    organizationId?: string
    organizationName?: string
  }
}

type Users = {
  total: number
  users: User[]
}

export type UserResponse = {
  users: Users
}
const defaultSelectedUser = {
  user_id: '',
  name: '',
  email: '',
  app_metadata: {},
  user_metadata: {
    organizationId: '',
    organizationName: '',
  },
}

const UsersTable: React.FC<UsersTableProps> = ({ pageSize = 20 }) => {
  const [searchTerm, changeSearchTerm] = useState<string>()
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const history = useHistory()
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0)
  const [usersToDisplay, setUsersToDisplay] = useState(allUsers)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [openVerifyModal, setOpenVerifyModal] = React.useState(false)
  const [selectedUser, setSelectedUser] = useState<User>(defaultSelectedUser)
  const { getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const searchTermQueryParam = searchTerm ? `&search=${searchTerm}` : ''
      const usersUrl = `${APIS.AUTH0}?service=users&page=${currentPageNumber}${searchTermQueryParam}`
      let isMounted = true
      const fetchUsers = async (): Promise<void> => {
        const accessToken = await trackPromise(
          getAccessTokenSilently(AccessTokenOptions),
        )
        trackPromise(
          fetch(usersUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => {
              if (response.ok) return response.json()
              else throw response
            })
            .then((jsonResponse: UserResponse) => {
              const total: number = jsonResponse.users.total
              const allUsers: User[] = jsonResponse.users.users
              if (isMounted) {
                setTotalUsers(total)
                setAllUsers(allUsers)
              }
            })
            .catch((err) => {
              err.text().then((text: string) => console.error(text))
            }),
        )
      }
      fetchUsers().then()

      return (): void => {
        isMounted = false
      }
    }, 500)
    return (): void => clearTimeout(delayDebounceFn)
  }, [currentPageNumber, searchTerm])

  React.useEffect(() => {
    if (allUsers) {
      setUsersToDisplay(allUsers)
      setTotalPages(Math.ceil(totalUsers / pageSize))
    }
  }, [allUsers, totalUsers, pageSize])

  return (
    <>
      <StyledSearchWrapper>
        <Search
          searchChangeHandler={changeSearchTerm}
          title='userTableSearch'
        />
      </StyledSearchWrapper>
      <StyledTableWrapper>
        <StyledTable>
          <THead>
            <TR>
              <TH>Name</TH>
              <TH>Email Address</TH>
              <TH>Organization</TH>
              <TH>Status</TH>
              <TH>Product</TH>
              <TH></TH>
              <TH></TH>
            </TR>
          </THead>
          <TBody>
            {usersToDisplay &&
              usersToDisplay.map((user) => {
                const status = user.user_metadata?.organizationId
                  ? 'Vetted'
                  : 'Unvetted'
                const verify = status === 'Unvetted' ? 'Verify' : ''
                const organizationToDisplay =
                  user.user_metadata?.organizationName &&
                  user.user_metadata?.organizationId
                    ? `${user.user_metadata.organizationName} (${user.user_metadata.organizationId})`
                    : ''
                return (
                  <StyledRow key={user.user_id} status={status}>
                    <TD style={{ width: '25%' }}>{user.name}</TD>
                    <TD style={{ width: '25%' }}>{user.email}</TD>
                    <TD>{organizationToDisplay}</TD>
                    <TD className='status'>{status}</TD>
                    <TD />
                    <TD
                      className='verify'
                      onClick={(): void => {
                        setOpenVerifyModal(!openVerifyModal)
                        setSelectedUser(user)
                      }}
                    >
                      {verify}
                    </TD>
                    <TD className='edit'>
                      <Icon
                        variant='edit'
                        onClick={(): void =>
                          history.push(`/user/${user.user_id}`)
                        }
                      />
                    </TD>
                  </StyledRow>
                )
              })}
          </TBody>
        </StyledTable>
      </StyledTableWrapper>
      {openVerifyModal ? (
        <VerifyUserModal
          setOpen={setOpenVerifyModal}
          open={openVerifyModal}
          actionText='Verify'
          selectedUser={selectedUser}
        />
      ) : null}
      <Pagination
        currentPage={currentPageNumber}
        setPage={setCurrentPageNumber}
        totalPages={totalPages}
      />
    </>
  )
}
export default UsersTable
